import React from 'react';
import { Link } from 'gatsby';
import Helmet from 'react-helmet';
import Layout from '../components/layout';
import Banner from '../components/Banner';

import pic01 from '../assets/images/pic01.jpg';
import pic02 from '../assets/images/pic02.jpg';
import pic03 from '../assets/images/pic03.jpg';
import pic04 from '../assets/images/pic04.jpg';
import pic05 from '../assets/images/pic05.jpg';
import pic06 from '../assets/images/pic06.jpg';

class HomeIndex extends React.Component {
  render() {
    return (
      <Layout>
        <Helmet
          title="Horovatin Consulting Ltd."
          meta={[
            {
              name: 'description',
              content:
                'An Alberta company offering human resources consulting services and salary surveys for Alberta municipalities.'
            },
            {
              name: 'keywords',
              content:
                'HR, Human Resources, Salary Survey, Alberta, Canada, compensation'
            },
            {
              name: 'google-site-verification',
              content: 'QxcgZhBNJPithPqtAcToEMiR8ogAjgxmuoRgVAU8lq0'
            }
          ]}
        />

        <Banner />

        <div id="main">
          <section id="one" className="tiles">
            <article style={{ backgroundImage: `url(${pic05})` }}>
              <header className="major">
                <h3>HR Support</h3>
                <p>Human Resources support services</p>
              </header>
              <Link
                to="/hrsupport"
                className="link primary"
                aria-label="HR Support"
              />
            </article>
            <article style={{ backgroundImage: `url(${pic04})` }}>
              <header className="major">
                <h3>Compensation</h3>
                <p>Need advice on employee compensation?</p>
              </header>
              <Link
                to="/compensation"
                className="link primary"
                aria-label="Compensation"
              />
            </article>
            <article style={{ backgroundImage: `url(${pic01})` }}>
              <header className="major">
                <h3>Policies</h3>
                <p>Let us help you define company policies and practices</p>
              </header>
              <Link
                to="/policies"
                className="link primary"
                aria-label="Company Policies"
              />
            </article>
            <article style={{ backgroundImage: `url(${pic02})` }}>
              <header className="major">
                <h3>Salary Structures</h3>
                <p>
                  We can help you define salary structures and custom market
                  pricing
                </p>
              </header>
              <Link
                to="/salary_structures"
                className="link primary"
                aria-label="Salary Structures"
              />
            </article>
            <article style={{ backgroundImage: `url(${pic06})` }}>
              <header className="major">
                <h3>Surveys</h3>
                <p>Customized salary surveys</p>
              </header>
              <Link
                to="/surveys"
                className="link primary"
                aria-label="Salary Surveys"
              />
            </article>
            <article style={{ backgroundImage: `url(${pic03})` }}>
              <header className="major">
                <h3>About Us</h3>
                <p>Who are we?</p>
              </header>
              <Link
                to="/about"
                className="link primary"
                aria-label="About Us"
              />
            </article>
          </section>
          {/*                     <section id="two">
                        <div className="inner">
                            <header className="major">
                                <h2>Massa libero</h2>
                            </header>
                            <p>Nullam et orci eu lorem consequat tincidunt vivamus et sagittis libero. Mauris aliquet magna magna sed nunc rhoncus pharetra. Pellentesque condimentum sem. In efficitur ligula tate urna. Maecenas laoreet massa vel lacinia pellentesque lorem ipsum dolor. Nullam et orci eu lorem consequat tincidunt. Vivamus et sagittis libero. Mauris aliquet magna magna sed nunc rhoncus amet pharetra et feugiat tempus.</p>
                            <ul className="actions">
                                <li><Link to="/landing" className="button next">Get Started</Link></li>
                            </ul>
                        </div>
                    </section>
 */}{' '}
        </div>
      </Layout>
    );
  }
}

export default HomeIndex;
